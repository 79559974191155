import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Spinner,
  Text,
  View,
  VStack,
  Modal,
  FormControl,
  Input,
} from "native-base";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import GetDishWithID from "../components/GetDishWithID";
import {
  RefundUserOrder,
  setNotifySound,
  SetOrderStatus,
} from "../Redux/actions";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useMount, useSetState } from "react-use";
import { useAppContext } from "../context";

const Orders = (props) => {
  const history = useHistory();
  const id = props.match.params.id;
  const { auth } = props;

  const [count, setCount] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);

  const dispatch = useDispatch();
  const isFirstLogin = useSelector(
    ({ state: { isFirstLogin } }) => isFirstLogin
  );
  console.log("isFirstLogin", isFirstLogin);

  useEffect(() => {
    if (isFirstLogin) {
      handleClickStart();
    }
  }, [isFirstLogin]);
  const handleClickStart = (event) => {
    setState2({
      run: true,
    });
  };
  const [{ run, steps2 }, setState2] = useSetState({
    run: false,
    steps2: [
      {
        content:
          "This is the table number or location where the order was submitted from, where the customer would like to receive and enjoy their order. Remember 101 is front entrance way.",
        target: ".routeD",
        // disableBeacon: true,
      },
      {
        content:
          "This is the name of the dish being ordered, as it is displayed in Piatto.",
        target: ".routeE",
      },
      {
        content:
          "These are all the options that were selected for the dish, including the necessary decisions.",
        target: ".routeF",
      },
      {
        content: "This is the first name of the customer who placed the order.",
        target: ".routeG",
      },
      {
        content:
          "This column indicates whether the order is a Dine-In or Takeaway order.",
        target: ".routeH",
      },
      {
        content:
          "This column indicates the accessibility needs of the customer, whether they are in a wheelchair, deaf, blind or require a sensory minimal location to enjoy their order. Please be mindful of this and accomodate, such that if your venue is up-stairs and the customer has indicated they are in a wheelchair, please consider taking the order to the bottom of the stairs. If the customer has indicated that they are deaf, please consider that shouting their first name when their order is complete would not be sufficient. If the customer has indicated that they are blind, please kindly show them to a table or deliver their order to them, saying their name, checking you have their attention and kindly placing the order in their hand. If the customer has indicated that they require a sensory minimal location to enjoy their order, please be alert for this customer, kindly show them to a table or deliver their order to them, saying their name, checking you have their attention and kindly offering the order for them to take out of your hand.",
        target: ".routeI",
      },
      {
        content:
          "This column will indicate the registration number of the car being driven by the customer. If your venue is near to the road or a underground carpark and the customer as indicated an accessibility requirement, please consider bringing their takeaway order to their car.",
        target: ".routeJ",
      },
      {
        content:
          "This column will displayed the charge that was applied to the customer for this order. If anything looks out of the ordinary, please tap the Raise Concerns button on this order and submit a query to Piatto.",
        target: ".routeK",
      },
    ],
  });
  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    console.log("status", status);
    if (finishedStatuses.includes(status)) {
      setState2({ run: false });
    }
    if(status === 'finished'){
      dispatch({ type: "ISFIRSTLOGIN", payload:false});
    }
  };
  useFirestoreConnect([
    {
      collection: "Orders",
      where: [["bizId", "==", `${id}`]],
      orderBy: [["timestamp", "asc"]],
    },
  ]);
  const Orders = useSelector(({ firestore: { ordered } }) => ordered.Orders);


  useFirestoreConnect([
    {
      collection: "Business",
      where: [["bizId", "==", `${id}`]],
      orderBy: [["timestamp", "asc"]],
    },
  ]);
  const SingleBusiness = useSelector(
    ({ firestore: { ordered } }) => ordered.Business
  );

  useEffect(() => {
    if (isLoaded(Orders)) {
      if (Orders.length !== count) {
        dispatch(setNotifySound(true));
        setCount(Orders.length);
      }
    }
  }, [Orders]);

  const handleRefund = async() => {
    setShowModal(false);
    console.log('run here')
    const response = await fetch("http://localhost:9000/refund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

    });
  }

  if (!auth.uid) return <Redirect to="/" />;
  if (isLoaded(Orders) && isLoaded(SingleBusiness)) {
    console.log(SingleBusiness);
    return (
      <VStack
        flex="1"
        _light={{ bg: "white" }}
        _dark={{ bg: "coolGray.800" }}
        space="3"
        justifyContent="space-between"
      >
        <Center w={"100%"}>
          <Text color={"white"} position={"absolute"} zIndex={99999} top={-50}>
            {SingleBusiness[Object.keys(SingleBusiness)[0]].bizName}
          </Text>

          <HStack alignSelf={"stretch"}>
            <Button onPress={() => history.push(`/Manage/${id}`)} flex={1}>
              Menu Management
            </Button>
            <Button
              flex={1}
              onPress={() => history.push(`/reservations/${id}`)}
              colorScheme={"orange"}
            >
              Reservation Management
            </Button>
            <Button
              onPress={() => history.push(`/Bussiness/${id}`)}
              flex={1}
              colorScheme={"green"}
            >
              Bussines Management
            </Button>
          </HStack>

          <VStack alignContent={"center"} w={"100%"} alignSelf={"stretch"}>
            <VStack w={"100%"}>
              <Heading alignSelf={"center"}>Orders</Heading>
              <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <Modal.Content maxWidth="400px">
                  <Modal.Body>
                    <Text
                      style={{
                        fontFamily: "Inclusive Sans, sans-serif",
                        fontSize: "18px",
                      }}
                      textAlign={"center"}
                    >
                      Are you sure you want to Cancel? The user will be notified
                      that their order has been cancelled and unless you now
                      select "Refund" they will not receive a refund!
                    </Text>
                  </Modal.Body>
                  <Modal.Footer
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Button.Group space={2}>
                      <Button
                        variant="ghost"
                        colorScheme="blueGray"
                        onPress={() => {
                          setShowModal(false);
                        }}
                      >
                        No, Exit
                      </Button>
                      <Button
                        onPress={() => {
                          setShowModal(false);
                        }}
                      >
                        Yes, Cancel
                      </Button>
                      <Button
                        onPress={handleRefund}
                        colorScheme={"red"}
                      >
                        Refund
                      </Button>
                    </Button.Group>
                  </Modal.Footer>
                </Modal.Content>
              </Modal>
              <VStack space={3} w={"100%"} alignItems={"center"}>
                <>
                  <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    run={run}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps2}
                    styles={{
                      options: {
                        zIndex: 10000,
                      },
                    }}
                  />
                  {Orders &&
                    Orders.map(function(item, i) {
                      if (item.OrderStatus !== "completed") {
                        return (
                          <Box
                            ml={2}
                            key={i}
                            w={"100%"}
                            shadow={5}
                            borderRadius={25}
                            padding={5}
                            bg={"coolGray.200"}
                          >
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Box
                                w={"80%"}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                <VStack mr={10}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeD">Table No</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        // <GetDishWithID
                                        //   orderTableNo={item["orderTableNo"]}
                                        //   item_id={ite["dishID"]}
                                        //   item={ite}
                                        // />
                                        <>1</>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeE">Dish Name</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "200px" }}>
                                          Double ChessBurger
                                        </div>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeF">Dish Options</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "200px" }}>
                                          1 fried egg. chicken salt, sweet
                                          potato
                                        </div>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeG">First Name</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return <>Bob</>;
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeH">
                                      DineIn / Pickup
                                    </div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "150px" }}>
                                          Will they sit to eat or takeaway?
                                        </div>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeI">Accessibility</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "150px" }}>
                                          Wheelchair, Deaf, Blind, Sensory
                                          Minimal
                                        </div>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={10}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    
                                    <div className="routeJ">Car Rego</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "120px" }}>
                                          123EFG
                                        </div>
                                      );
                                    })}
                                </VStack>
                                <VStack mr={30}>
                                  <Text
                                    style={{
                                      fontFamily: "Inclusive Sans, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    color={"pink.700"}
                                  >
                                    <div className="routeK">Price</div>
                                  </Text>
                                  {item.dishes &&
                                    item.dishes.map(function(ite, i) {
                                      console.log(
                                        "showing",
                                        ite["dishID"],
                                        ite
                                      );

                                      return (
                                        <div style={{ width: "150px" }}>64</div>
                                      );
                                    })}
                                </VStack>
                              </Box>
                              {/* <Button
                                variant={
                                  item.OrderStatus === "waiting"
                                    ? "subtle"
                                    : "solid"
                                }
                                colorScheme={"coolGray"}
                              >
                                {item.OrderStatus}
                                {item.OrderStatus === "waiting" && (
                                  <Spinner color={"blue.500"} />
                                )}
                              </Button> */}
                              <VStack
                                space={1}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <VStack display={"flex"} flexDirection={"row"}>
                                  <Button
                                    isDisabled={
                                      item.OrderStatus === "preparing"
                                    }
                                    marginRight={2}
                                    onPress={() =>
                                      dispatch(
                                        SetOrderStatus(
                                          item,
                                          "preparing",
                                          auth.stsTokenManager.accessToken,
                                          SingleBusiness[
                                            Object.keys(SingleBusiness)[0]
                                          ].bizName
                                        )
                                      )
                                    }
                                    colorScheme={"orange"}
                                    size={"xs"}
                                  >
                                    Set to Preparing
                                  </Button>
                                  <Button
                                    onPress={() =>
                                      dispatch(
                                        SetOrderStatus(
                                          item,
                                          "completed",
                                          auth.stsTokenManager.accessToken,
                                          SingleBusiness[
                                            Object.keys(SingleBusiness)[0]
                                          ].bizName
                                        )
                                      )
                                    }
                                    colorScheme={"green"}
                                    size={"xs"}
                                  >
                                    move to Completed
                                  </Button>
                                </VStack>
                                <VStack display={"flex"} flexDirection={"row"}>
                                  <Button
                                    colorScheme={"red"}
                                    size={"xs"}
                                    marginRight={2}
                                    onPress={handleClickStart}
                                  >
                                    Raise Concern
                                  </Button>
                                  <Button
                                    colorScheme={"red"}
                                    size={"xs"}
                                    onPress={() => {
                                      setShowModal(true);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </VStack>
                                {/* <Button
                                  onPress={() =>
                                    dispatch(
                                      RefundUserOrder(
                                        item,
                                        SingleBusiness[
                                          Object.keys(SingleBusiness)[0]
                                        ].bizId,
                                        SingleBusiness[
                                          Object.keys(SingleBusiness)[0]
                                        ].bizName,
                                        auth.stsTokenManager.accessToken
                                      )
                                    )
                                  }
                                  colorScheme={"red"}
                                  size={"xs"}
                                >
                                  Refund
                                </Button> */}
                              </VStack>
                            </HStack>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    })}
                </>
              </VStack>
            </VStack>
            <Divider
              thickness="6"
              bg="coolGray.500"
              ml={2}
              mr={2}
              orientation="horizontal"
            />
            <VStack w={"100%"}>
              <Heading alignSelf={"center"}>Completed</Heading>
              <VStack space={3} w={"100%"} alignItems={"center"}>
                <>
                  {Orders &&
                    Orders.map(function(item, i) {
                      if (item.OrderStatus === "completed") {
                        return (
                          <Box
                            ml={2}
                            w={"100%"}
                            shadow={5}
                            borderRadius={25}
                            bg={"coolGray.200"}
                          >
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Text>{item.userId}</Text>
                              <Button mr={5} h={"100%"} colorScheme={"green"}>
                                completed
                              </Button>
                            </HStack>
                            <VStack>
                              {item.dishes &&
                                item.dishes.map(function(item, i) {
                                  return <GetDishWithID id={item} />;
                                })}
                            </VStack>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    })}
                </>
              </VStack>
            </VStack>
          </VStack>
        </Center>
      </VStack>
    );
  } else {
    return (
      <View>
        <Center>
          <Spinner size={"lg"} />
        </Center>
      </View>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(Orders);
